<template>
  <div>
    <div class="top-wrap">
      <div class="left-status" style="position: relative;">
        <div class="icon"></div>
        <div class="chinese">{{data.status==1?'营业中':'停业'}}</div>
        <div class="english">{{data.status==1?'OPEN':'STOP'}}</div>
        <img @click="openQrcode" class="qrcode" src="../../../assets/code.png">
      </div>
      <div class="top-tip">
        <div class="tip-title color">运营情况</div>
        <div class="nav-wrap">
          <div class="wrap-item">到期时间 ： {{data.expiration_time}}</div>
          <div class="wrap-item">总营业额 ： {{data.order_sum}}</div>
          <div class="wrap-item">总用户数 ： {{data.user_count}}</div>
          <div class="wrap-item">短信剩余 ： {{data.message_num}}</div>
          <div class="wrap-item">总单量 ： {{data.order_count}}</div>
          <div class="wrap-item">总访问数 ： {{data.views_num}}</div>
        </div>
      </div>
    </div>
    <div class="middle-wrap">
      <div class="bottom-title">店铺财务</div>
      <div class="view_box">
        <div style="border-right:1px solid #eee" class="item_view_box">
          <div class="view_title">待处理订单</div>
          <div class="item_num">{{daysData.wait_order_count}}</div>
        </div>
        <div style="border-right:1px solid #eee" class="item_view_box">
          <div class="view_title">营业额</div>
          <div class="item_num">{{daysData.order_sum}}</div>
        </div>
        <div style="border-right:1px solid #eee" class="item_view_box">
          <div class="view_title">新增会员</div>
          <div class="item_num">{{daysData.member_count}}</div>
        </div>
        <div style="border-right:1px solid #eee" class="item_view_box">
          <div class="view_title">订单数</div>
          <div class="item_num">{{daysData.order_count}}</div>
        </div>
        <div class="item_view_box">
          <div class="view_title">退款单</div>
          <div class="item_num">{{daysData.refund_order_count}}</div>
        </div>
      </div>
      <!-- <div class="r-choose">
        <div class="choose-item "
             :class="activeIndex === index ? 'active' : '' "
             @click="chooseType(index)"
             v-for="(item,index) in chooseList"
             :key="index">{{ item.label }}
        </div>
      </div> -->
      <EchartLine v-if="status" id="three" width="100%" title="订单数据" :xAxis="xAxisData" :series="seriesData"></EchartLine>
    </div>
    <div style="height:10px"></div>
    <Modal
        v-model="show_qrcode"
        title="二维码"
        :mask-closable="false">
        <div style="display: flex;justify-content: center;align-items: center;text-align: center;">
          <div v-if="data.qrcode.wm_qrcode">
              <img style="width:200px;height:200px" :src="data.qrcode.wm_qrcode" />
              <div>外卖</div>
          </div>
          <div style="width:20px"></div>
          <div v-if="data.qrcode.zq_qrcode">
              <img style="width:200px;height:200px" :src="data.qrcode.zq_qrcode" />
              <div>自取</div>
          </div>
        </div> 
        <div slot="footer">
            <Button @click="getCode">刷新</Button>
            <Button class="btn" @click="show_qrcode = false">关闭</Button>
        </div>
    </Modal>
  </div>
</template>
<script>
// import {listMenu} from '@/api/setting'
import {EchartLine} from '@/components/eChart/index'
import {home,days,addMenu, getCode} from '@/api/index'
export default {
  components: {
    EchartLine,
  },
  data() {
    return {
      show_qrcode:false,
      value1: '1',
      data:{
        qrcode:{
          wm_qrcode:"",
          zq_qrcode:""
        }
      },
      jurisdiction:{
        pid:'',
        src:'',
        title:'',
        is_menu:''
      },
      status:false,
      daysData:{},
      chooseList: [
        {
          label: '本周',
          type: ''
        },
        {
          label: '本月',
          type: ''
        },
        {
          label: '本年',
          type: ''
        },
      ],
      worlkColumns: [
        {
          title: '店铺名称',
          key: 'name',
        },
        {
          title: '单量',
          key: 'age'
        },
        {
          title: '金额',
          key: 'address'
        },
      ],
      data6: [
        {
          name: 'John Brown',
          age: 18,
          address: 'New York No. 1 Lake Park'
        },
        {
          name: 'Jim Green',
          age: 24,
          address: 'London No. 1 Lake Park'
        },
        {
          name: 'Joe Black',
          age: 30,
          address: 'Sydney No. 1 Lake Park'
        },
        {
          name: 'Jon Snow',
          age: 26,
          address: 'Ottawa No. 2 Lake Park'
        }
      ],
      activeIndex: 0,
      xAxisData: [],
      seriesData: [{
        type: 'line',
        data: []
      }],
    }
  },
  mounted() {
  },
  created() {
    this.home()
    this.days()
    // this.listMenu()
  },
  methods: {
    openQrcode(){
      this.show_qrcode = true
    },
    getCode(){
        getCode().then(res=>{
            this.data.qrcode = res.data
        })
    },
    home(){
      home().then(res=>{
        this.data = res.data
      })
    },
    // listMenu(){
    //   listMenu().then(res=>{
    //     console.log(res);
    //     console.log(this.$store.state.menu);
    //   })
    // },
    days(){
      days().then(res=>{
        let data = res.data.time_data
        data.forEach(item => {
          this.xAxisData.push(item.date)
          this.seriesData[0].data.push(item.val)
        });
        this.daysData = res.data
        this.status = true
      })
    },
    jurisdictionBtn(){
      addMenu(this.jurisdiction).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    chooseType(index) {
      this.activeIndex = index
    }
  }
}
</script>
<style scoped>

.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.qrcode{
  width:20px;
  height:20px;
  position: absolute;
  top:10px;
  right:10px;
  z-index:99;
  cursor: pointer;
}
.top-tip {
  flex: 1;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  background: white;
}

.top-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.bottom-wrap {
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}

.view_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}

.item_view_box{
  text-align: center;
  width: 20%;
}

.item_num{
  font-weight: 700;
  font-size: 20px;
}

.view_title{
  color: #999;
  font-size: 16px;
}

.middle-wrap {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}

.mid-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.bottom-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.bottom-title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  margin-right: 6px;
  background: #FF761D;
  border-radius: 4px;
}

.tip-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.tip-title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  margin-right: 6px;
  background: #FF761D;
  border-radius: 4px;
}

.b-info {
  margin-top: 10px;
  display: flex;
}

.col-title {
  font-size: 15px;
  color: #585858;
}

.col-value {
  font-size: 18px;
  color: #343434;
  font-weight: bold;
  margin-top: 12px;
}

.cloum {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cloum:not(:last-child) {
  border-right: 2px solid #d7dde4;
}

.r-choose {
  display: flex;
  align-items: center;
  position: absolute;
  right: 45px;
  top: 30px;
}

.choose-item {
  background: white;
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #737373;
  font-size: 16px;
  border: 2px solid #E5E5E5;
  border-radius: 4px;
  cursor: pointer;
}

.choose-item.active {
  border: 2px solid #FF761D;
  color: #FF761D;
}

.table-style {
  margin-top: 16px;
}

.left-status {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.chinese {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.english {
  font-size: 16px;
  font-weight: bold;
  margin-top: 6px;
  color: #8D8D8D;
}
.nav-wrap {
  display: flex;
  flex-wrap: wrap;
}
.nav-wrap .wrap-item {
  width: 33.33%;
  font-size: 16px;
  margin-top: 30px;
}
</style>
<style>
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
</style>