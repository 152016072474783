import request from '@/utils/request'

export function getStoreData (data) {
    return request({
        url: '/data/getStoreData',
        method: 'post',
        data
    })
}

export function saveData (data) {
    return request({
        url: '/data/saveData',
        method: 'post',
        data
    })
}

export function getStoreImg (data) {
    return request({
        url: '/data/getStoreImg',
        method: 'post',
        data
    })
}

export function setStoreImg (data) {
    return request({
        url: '/data/setStoreImg',
        method: 'post',
        data
    })
}

export function imagesHandle (data) {
    return request({
        url: '/data/imagesHandle',
        method: 'post',
        data
    })
}

export function home (data) {
    return request({
        url: '/data/home',
        method: 'post',
        data
    })
}

export function days (data) {
    return request({
        url: '/data/days',
        method: 'post',
        data
    })
}

export function getCode (data) {
    return request({
        url: '/data/getCode',
        method: 'post',
        data
    })
}

export function updatePwd (data) {
    return request({
        url: '/updatePwd',
        method: 'post',
        data
    })
}

export function getLocation (data) {
    return request({
        url: '/data/getLocation',
        method: 'post',
        data
    })
}

export function addMenu (data) {
    return request({
        url: '/apply/addMenu',
        method: 'post',
        data
    })
}

export function setStoreLogo (data) {
    return request({
        url: '/data/conf/setStoreLogo',
        method: 'post',
        data
    })
}

export function setStoreData (data) {
    return request({
        url: '/data/conf/setStoreData',
        method: 'post',
        data
    })
}