<template>
  <div class="page-style">
    <div class="left-menu">
      <div class="menu-nav" v-for="(item,index) in leftMenu"
           :class="activeIndex === item.src ? 'active' : ''"
           :key="index" @click="changeLabel(item)">
        <Icon :type="item.icon" size="24" style="margin-right: 10px"/>
        {{ item.title }}</div>
    </div>
    <div class="right-content">
      <div class="content-del">
        <div v-if="activeIndex === '/admin/details'"><Detail></Detail></div>
        <div v-if="activeIndex === '/admin/information'"><Business></Business></div>
        <div v-if="activeIndex === '/admin/picture'"><Picture></Picture></div>
        <div v-if="activeIndex === '/admin/password'"><Password></Password></div>
      </div>
    </div>

  </div>
</template>
<script>
import Password from "./components/password";
import Detail from "./components/detail";
import Business from "./components/business";
import Picture from "./components/picture";
export default {
  components: {Password,Detail,Business,Picture},
  data() {
    return {
      leftMenu:[
        {
          icon:'ios-albums-outline',
          label:'详情',
          path:'/admin/details'
        },
        {
          icon:'ios-home-outline',
          label:'商家信息',
          path:'/admin/information'
        },
        {
          icon:'ios-image-outline',
          label:'门店图片',
          path:'/admin/picture'
        },
        {
          icon:'ios-cog-outline',
          label:'修改密码',
          path:'/admin/password'
        },
      ],
      activeIndex:0
    }
  },
  mounted() {
  },
  created() {
    let menu = this.$store.state.menu
    menu.forEach(item => {
      if (item.id==12) {
        if (this.$store.state.activeIndex==0) {
          this.activeIndex = item.children[this.$store.state.activeIndex].src
        }else{
          this.activeIndex = this.$store.state.activeIndex
        }
        this.leftMenu = item.children
      }
    });
  },
  methods: {
    changeLabel(item){
      this.$store.commit('setActiveIndex', item.src)
      this.activeIndex = item.src
    }
  }
}
</script>
<style scoped>
.page-style {
  display: flex;

}

.left-menu {
  width: 210px;
  max-width: 210px;
  min-width: 210px;
  height: 93vh;
  background: white;
  margin-right: 20px;
  padding: 20px 20px;
  box-sizing: border-box;
}

.right-content {
  flex: 1;
  padding: 20px 20px 0 0;
  box-sizing: border-box;
}
.content-del {
  /*background: #fff;*/
  width: 100%;
  height: 88vh;
  border-radius: 8px;
  overflow: auto;
}
.menu-nav {
  border: 1px solid #FFF;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 200px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.menu-nav:hover{
  background: #FFF7F2;
  color: #686868;
}
.menu-nav.active{
  /* background: #FF761D; */
  border: 1px solid #FF761D;
  color: #FF761D;
  /* color: white; */
}

</style>
