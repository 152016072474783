<template>
  <div class="son-page">
    <Form :model="formItem" :label-width="140" style="width: 500px;margin-top: 20px">
      <FormItem label="原始密码：">
        <Input v-model="formItem.password" type="password" placeholder="请输入原始密码"></Input>
      </FormItem>
      <FormItem label="新密码：">
        <Input v-model="formItem.update_password" type="password" placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem label="再次输入：">
        <Input v-model="update_password" type="password" placeholder="请再次输入新密码"></Input>
      </FormItem>
    </Form>
    <div class="bottom">
      <div @click="addBtn" class="btn">保存</div>
    </div>
  </div>
</template>

<script>
import {updatePwd} from '@/api/index'
export default {
  name: "password",
  data() {
    return {
      update_password:'',
      formItem: {
        password:'',
        update_password:''
      },
    }
  },
  created() {

  },
  methods: {
    addBtn(){
      if (this.update_password == this.formItem.update_password) {
        updatePwd(this.formItem).then(res=>{
          this.$Message.success(res.msg)
          this.formItem.password = ''
          this.formItem.update_password = ''
          this.update_password = ''
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        this.$Message.error('两次密码输入不一致')
      }
    },
  }
}
</script>

<style scoped>
.son-page {
  padding: 20px;
  box-sizing: border-box;
  height: 88vh;
  background: #fff;
  position: relative;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 2px solid #F6F6F6;
  padding: 20px 60px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.btn {
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FD781A;
  color: #fff;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}
.btn:hover{
  opacity: 0.6;
}
</style>
